import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from 'easyship-components';
import { Form } from '@/components/Form';
import { PdfAgreement } from './pdfAgreement';
import { useState } from 'react';
import { ControlledInput } from '@/components/ControlledInput';

export const COMPATIBLE_UMBRELLA_NAMES = {
  fedex: 'FedEx',
  fedexCrossBorder: 'FedEx Cross Border',
  fedexParcelcast: 'FedEx-Parcelcast',
} satisfies Record<string, string>;

export const FormComponent = ({ onSuccess }: CourierFormComponentProps<FormValues>) => {
  // TODO: we need to fetch address data and prefill the form
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      addressLine1: '15 W 18TH ST FL 7',
      postalCode: '100114624',
      city: 'NEW YORK',
      state: 'NY',
      country: 'US',
      accountName: 'Test',
      accountNumber: '700257037',
      email: 'abel+000@easyship.com',
      companyName: 'Test',
      name: 'Abel Lopes',
      phoneNumber: '+351900000000',
    },
  });

  return (
    <>
      <p>
        By connecting your FedEx account, you will no longer be eligible to use the discounted FedEx
        rates offered by Easyship.
      </p>
      {!isAgreementAccepted ? (
        <PdfAgreement
          umbrellaName={COMPATIBLE_UMBRELLA_NAMES.fedexCrossBorder}
          acceptAgreement={setIsAgreementAccepted}
        />
      ) : (
        <Form onSubmit={handleSubmit(onSuccess)}>
          <ControlledInput
            name="accountName"
            control={control}
            label="Account Name"
            placeholder="e.g. MyCarrierAccount 01"
            statusText="The account name will be used to identify your account"
          />

          <ControlledInput
            name="accountNumber"
            control={control}
            label="FedEx Account Number"
            placeholder="e.g. 670116009"
          />

          <ControlledInput name="companyName" control={control} label="Company Name" />

          <ControlledInput name="name" control={control} label="Name" />

          <ControlledInput name="phoneNumber" control={control} label="Phone Number" />

          <ControlledInput name="email" control={control} label="Email" type="email" />

          <Alert>
            <p>
              The address below should be the same as your current FedEx account billing address.
            </p>
          </Alert>

          <ControlledInput name="country" control={control} label="Country" />

          <ControlledInput name="postalCode" control={control} label="Postal Code" />

          <ControlledInput name="city" control={control} label="City" />

          <ControlledInput name="state" control={control} label="State/Province" />

          <ControlledInput name="addressLine1" control={control} label="Address Line 1" />

          <ControlledInput name="addressLine2" control={control} label="Address Line 2" />

          <p className="text-xs mt-3 text-ink-100">
            FedEx service marks are owned by Federal Express Corporation and are used by permission.
          </p>

          <pre>
            <code>{JSON.stringify(formState.errors)}</code>
          </pre>
        </Form>
      )}
    </>
  );
};
