/**
 * NOTE: sorting affects router behavior
 * NOTE: do not use numeric keys to prevent JS auto sorting
 */

export const routesList = {
  home: '/',
  courierList: '/couriers',
  addCourier: '/couriers/:id',
  success: '/success',
  error: '/error',
  notFound: '*',
} as const;

export type RouteIndex = keyof typeof routesList;

export type AppRoute = (typeof routesList)[RouteIndex];
