import React, { useEffect } from 'react';
import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';

export const COMPATIBLE_UMBRELLA_NAMES = {
  dhl: 'DHL',
  dhlImport: 'DHL Import',
  dhlECommerce: 'DHL eCommerce',
  dhlECommerceMetro: 'DHL eCommerce Metro',
} satisfies Record<string, string>;

export const FormComponent = ({
  onSuccess,
}: CourierFormComponentProps<FormValues>): React.ReactElement => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      <ControlledInput
        name="accountName"
        control={control}
        label="Account Name"
        placeholder="e.g. MyCarrierAccount 01"
        statusText="The account name will be used to identify your account"
      />

      <ControlledInput
        name="accountNumber"
        control={control}
        label="Account Number"
        placeholder="e.g. 123456789"
        statusText="digit Export Account ID accessible from MyDHL"
      />

      <ControlledInput
        name="apiKey"
        control={control}
        label="API Key"
        statusText="14-character key on the DHL Developer Portal"
      />

      <ControlledInput
        name="apiSecret"
        control={control}
        label="API Secret"
        statusText="16-character key on the DHL Developer Portal"
      />
    </Form>
  );
};
