import React from "react";

export interface LayoutProps {
  title: string;
  children: React.ReactNode;
}

export const Layout = ({
  title,
  children,
}: LayoutProps): React.ReactElement => (
  // TODO: remove width and padding when development is done
  <div className="text-ink-900 text-base h-full w-full min-w-[404px] max-w-[704px] box-border p-2 flex flex-col">
    <div className="relative grow">
      <h1 className="text-xl mb-5">{title}</h1>
      {children}
    </div>

    <img src="/powered_by.svg" />
  </div>
);
